<template>

    <div class="vue-box">

        <div class="header">
            <el-row>
                <el-col :span="4">
                    <div class="title">合同款统计</div>
                </el-col>
                <el-col :span="16">
                     <button-project enumName="ProjectStateEnum"   @fatherMethod="ok"></button-project>
                </el-col>
            </el-row>
        </div>

        <div class="c-panel">
            <el-row v-for="g,index in group" justify="space-between" :key="index">
                <el-col :span="6" v-for="item in g" :key="item.id">
                    <card-finance :project="item" :counts="counts"></card-finance>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import ButtonProject from '../../sa-resources/com-view/button-project.vue';
import inputEnum from '../../sa-resources/com-view/input-enum.vue';
import CardFinance from './card-finance.vue';
export default {
    components: { inputEnum,CardFinance, ButtonProject },
    data() {
        return {
            group: [],
            title: "项目实施统计",
            state: '',
            counts: [],
            p: {
                pageSize: 100,
                page: 1,
                state: 1
            }

        }
    },
    methods: {
        ok(state) {
            this.p.state = state;
            this.f5();
            console.log("查询", this.state)
        },
        f5() {
            this.group = [];
             this.sa.get("/finance/groupByProjectId").then(res => {
                this.counts = res.data;
            })

            this.sa.put("/project/listPage", this.p).then(res => {
                console.log(77788, res.data.content)
                let item = [];
                res.data.content.forEach((element, index) => {
                    item.push(element);
                    if ((index + 1) % 4 == 0) {
                        this.group.push(item);
                        item = [];
                    }
                });
                if (item.length > 0) {
                    this.group.push(item)
                }
                console.log(6655, this.group);
            })
           
        },
        goto(){
            console.log(555555555555555);
            this.sa_admin.navigateTo("purchase-list", {id:-1, projectCode:''});
        }
    },
    created() {
        this.title = this.sa_admin.nativeTab.name;
        this.f5();


    }
}
</script>

<style scoped>
.title {
    width: 120px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 29px;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
}

.header {
    margin: 20px;
}
.c-panel {
    background-color: #eee;
}
</style>